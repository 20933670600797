<div class="container">
  <h1>
    Have Some Snapshot Links
  </h1>
  <p>
    Filthy animal.
  </p>
  <ul>
    <li *ngFor = "let filename of filenames">
        <a href="{{getDownloadLink(filename)}}">{{ filename }}</a>
  </ul>
</div>
