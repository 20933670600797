import { Component, OnInit } from '@angular/core';
import { CelticShiftJarService } from 'src/services/celticShiftJarService';

@Component({
  selector: 'header-bar',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  constructor() { }

  ngOnInit() {

  }
}
