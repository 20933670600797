import { Component, OnInit } from '@angular/core';
import { CelticShiftJarService } from 'src/services/celticShiftJarService';

@Component({
  selector: 'celticshift',
  templateUrl: './celticshift.component.html',
  styleUrls: ['./celticshift.component.less']
})
export class CelticShiftComponent implements OnInit {
  filenames: Array<string>;

  constructor(private celticShiftJarService: CelticShiftJarService) {
    celticShiftJarService.getFileList().subscribe(data => {
      this.filenames = data.filenames.reverse();
    });
  }

  ngOnInit() {

  }

  getDownloadLink(filename: string) {
    return this.celticShiftJarService.formatLinkFromFilename(filename);
  }
}
