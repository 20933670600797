import { Component, OnInit } from '@angular/core';
import { CelticShiftJarService } from 'src/services/celticShiftJarService';

@Component({
  selector: 'homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.less']
})
export class HomepageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
