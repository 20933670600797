import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CelticShiftComponent } from './celticshift/celticshift.component';
import { HomepageComponent } from './homepage/homepage.component';

const routes: Routes = [
  { path: 'celticshift', component: CelticShiftComponent },
  { path: '', component: HomepageComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
