import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'givesippy';
  audio = new Audio();
  started = false;
  invertColor = false;

  ngOnInit() {
    this.audio.src = "../assets/FUNALARM.mp3";
    this.audio.load();
  }

  runEyerape() {
    if (!this.started) {
      setInterval(() => {
        this.invertColor = !this.invertColor;
      }, 15);
    }
  }

  onMouse() {
    this.audio.play();
    this.runEyerape();
  }
}
